import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/tmp/89a06d9/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const AnchorLinks = makeShortcode("AnchorLinks");
const AnchorLink = makeShortcode("AnchorLink");
const Row = makeShortcode("Row");
const Column = makeShortcode("Column");
const ResourceCard = makeShortcode("ResourceCard");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <AnchorLinks mdxType="AnchorLinks">
      <AnchorLink mdxType="AnchorLink">Motion generator</AnchorLink>
      <AnchorLink mdxType="AnchorLink">Motion design strategy</AnchorLink>
      <AnchorLink mdxType="AnchorLink">Evaluation checklist</AnchorLink>
      <AnchorLink mdxType="AnchorLink">Adaptive interface motion design</AnchorLink>
    </AnchorLinks>
    <h2 {...{
      "id": "motion-generator"
    }}>{`Motion Generator`}</h2>
    <p>{`IBM Motion uses sophisticated curves and durations. Use the IBM Motion Generator to design accurate and effective motion.`}</p>
    <Row className="tile--group" mdxType="Row">
  <Column offsetLg="4" colMd="4" colLg="4" noGutterSm mdxType="Column">
    <ResourceCard title="Motion generator" href="https://ibm.github.io/motion/" type="resource" mdxType="ResourceCard" />
  </Column>
    </Row>
    <h2 {...{
      "id": "motion-design-strategy"
    }}>{`Motion design strategy`}</h2>
    <p>{`Follow these steps to assess your interface for purposeful motion:`}</p>
    <ol>
      <li parentName="ol">{`Define the goals and values of the product.`}</li>
      <li parentName="ol">{`Establish information hierarchy within the view.`}</li>
      <li parentName="ol">{`Identify the journey you will guide users through.`}</li>
      <li parentName="ol">{`Identify key moments and opportunities for UI motion in the journey. Examples:`}<ul parentName="li">
          <li parentName="ul">{`Give feedback, such as hover states and active state component behaviors like opening a dropdown menu`}</li>
          <li parentName="ul">{`Solutions to a UX need, such as reducing cognitive load with progressive disclosure`}</li>
          <li parentName="ul">{`Providing guidance, such as revealing a call to action to draw users’ attention`}</li>
        </ul></li>
      <li parentName="ol">{`Assign or determine either productive or expressive motion`}</li>
      <li parentName="ol">{`Prototype and test`}</li>
    </ol>
    <h2 {...{
      "id": "evaluation-checklist"
    }}>{`Evaluation checklist`}</h2>
    <ol>
      <li parentName="ol">{`Is your motion purposeful?`}<ul parentName="li">
          <li parentName="ul">{`What problem is motion solving?`}</li>
          <li parentName="ul">{`Does it enhance the user’s understanding of an action?`}</li>
        </ul></li>
      <li parentName="ol">{`Is your motion responsive?`}<ul parentName="li">
          <li parentName="ul">{`Do user actions receive immediate feedback that’s seen and felt?`}</li>
          <li parentName="ul">{`Do micro-interactions use `}<inlineCode parentName="li">{`ease-out`}</inlineCode>{` on user input?`}</li>
          <li parentName="ul">{`Do micro-interactions fall within a static duration ranging from 90–120 ms?`}</li>
          <li parentName="ul">{`If there are large, or full screen, transitions in your product, are there continuous elements in your transition to guide the user?`}</li>
        </ul></li>
      <li parentName="ol">{`Is your motion meticulous?`}<ul parentName="li">
          <li parentName="ul">{`Did you use the appropriate easing curves?`}</li>
          <li parentName="ul">{`Is each motion individually considered?`}</li>
          <li parentName="ul">{`Do related movements have a unified relationship with each other?`}</li>
          <li parentName="ul">{`Is the motion effective across all screen sizes? Keep in mind that on mobile screens, subtle motions may be easily missed.`}</li>
        </ul></li>
      <li parentName="ol">{`Is your motion unobtrusive?`}<ul parentName="li">
          <li parentName="ul">{`The best interface motion may go unnoticed, because it often keeps users engaged with their tasks. Is your motion frequently noticed by average users? If so, consider removing or minimizing it.`}</li>
        </ul></li>
    </ol>
    <h2 {...{
      "id": "adaptive-interface-motion-design"
    }}>{`Adaptive interface motion design`}</h2>
    <p>{`A large population of users exist with impaired vision or impaired ability to perceive and handle motion in UI. In addition, not all devices are powerful enough to smoothly perform all the motion you would like, no matter how essential the motion design is. Always provide alternatives for interface state transitions. Consider simplified or reduced motion designs for mobile and tablet. Make sure there is always a way to communicate similar messages statically.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      